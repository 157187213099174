.nuestros-servicios {
  text-align: center;
  padding: 20px 80px;
  color: #1370FB;
}

.nuestros-servicios .container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
}

.nuestros-servicios h1 {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 10px;
}

.nuestros-servicios h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 40px;
}

.servicios-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  box-shadow: 0 4px 15px #0000001a;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 30px;
}

.servicio-card {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease;
}

.servicio-card:hover {
  transform: translateY(-10px);
}

.servicios-cards.carrusel::-webkit-scrollbar {
  width: 8px;
  height: 8px; /* Asegurarse de que también se vea en la parte inferior en scroll horizontal */
}

.servicios-cards.carrusel::-webkit-scrollbar-thumb {
  background-color: #1370FB;
  border-radius: 10px;
}

.servicios-cards.carrusel::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

.icono img {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.servicio-card h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.servicio-card p {
  font-size: 14px;
  color: #667;
}

/* VISTA MÓVIL */
@media (max-width: 768px) {
  .nuestros-servicios {
    padding: 20px;
  }

  .servicios-cards.carrusel {
    display: flex;
    overflow-x: scroll;
  }

  /* Estilos para el scrollbar en Webkit */
  .servicios-cards.carrusel::-webkit-scrollbar {
    width: 8px;
  }

  .servicios-cards.carrusel::-webkit-scrollbar-thumb {
    background-color: #1370FB;
    border-radius: 10px;
  }

  .servicios-cards.carrusel::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 10px;
  }


  .servicio-card {
    flex: 0 0 90%;
    scroll-snap-align: start;
  }

  .nuestros-servicios h1 {
    font-size: 28px;
  }

  .nuestros-servicios h2 {
    font-size: 18px;
  }

  .servicio-card h3 {
    font-size: 16px;
  }

  .servicio-card p {
    font-size: 12px;
  }

  .icono img {
    width: 50px;
    height: 50px;
  }
}

/* Asegurar que el scrollbar sea visible en dispositivos móviles */
@media only screen and (max-width: 768px) {
  .servicios-cards.carrusel {
    -webkit-overflow-scrolling: touch; /* Scroll suave para iOS */
    overflow-x: scroll;
    scrollbar-width: thin; /* Scrollbar visible pero más delgado en navegadores compatibles */
    scrollbar-color: #1370FB #e0e0e0; /* Colores del scrollbar en navegadores compatibles */
  }

  /* Asegurar que el scrollbar no se oculte en móvil */
  .servicios-cards.carrusel::-webkit-scrollbar {
    display: block;
  }
}