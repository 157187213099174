/* NUESTRA MISIÓN HOME */
.nuestra-mision {
  padding: 10px 10px;
  text-align: center;
}

.mision-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.mision-media {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 50px;
}

.mision-video-large {
  border-radius: 20px;
  width: 300px;
  height: auto;
  margin-top: -20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mision-video-large:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.mision-images-small {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: -20px;
}

.mision-image-small {
  border-radius: 20px;
  width: 250px;
  height: 250px;
  margin-top: -20px;
  border: 2px solid #6e6f70;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.mision-image-small:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.mision-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin-top: 50px;
}

.nuestra-mision h2 {
  color: #1370FB;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 0px;
}

.mision-card {
  background: white;
  border-radius: 20px;
  padding: 30px;
  text-align: left;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mision-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.mision-text p {
  margin-bottom: 20px;
  text-align: justify;
  line-height: 1.5;
  font-size: 20px;
}

.mision-author {
  display: flex;
  align-items: center;
  gap: 10px;
  font-style: italic;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.mision-author img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.mision-author p {
  font-weight: 600;
}

/* Modal de imagen ampliada */
.modal {
  display: flex;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  max-height: 50%;
  position: relative;
}

.modal-image-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.modal-image-container img {
  width: 100%;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
}

.modal-image-container.sliding img {
  transform: translateX(-100%);
}

.modal-image-container.prev.sliding img {
  transform: translateX(100%);
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.modal-prev,
.modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 10000;
}

.modal-prev {
  left: 10px;
}

.modal-next {
  right: 10px;
}

/* Vista móvil */
@media (max-width: 768px) {
  .mision-content {
    flex-direction: column;
    align-items: center;
  }

  .mision-info {
    order: 1;
    margin-top: -10px;
    margin-bottom: -20px;
    font-size: 18px;
  }

  .mision-media {
    order: 2;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-top: 80px;
  }

  .mision-video-large {
    width: 180px;
    height: 320px;
    margin-top: -40px;
  }

  .mision-images-small {
    flex-direction: column;
    gap: 15px;
    margin-top: -30px;
  }

  .mision-image-small {
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .mision-text p {
    font-size: 16px;
  }

  .nuestra-mision h2 {
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .mision-card {
    max-width: 300px;
    margin-top: -10px;
  }

  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }
}
