body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Hace que el cuerpo tenga una altura mínima igual a la altura de la ventana */
  margin: 0; /* Elimina el margen predeterminado */
}

main {
  flex: 1; /* Hace que el contenido principal ocupe todo el espacio disponible */
}

.footer {
  margin-top: auto; /* Empuja el footer hacia el fondo */
  background-color: #0066ff; /* Mantén el fondo azul */
  color: white;
  padding: 30px 0;
  text-align: center;
  position: relative;
  width: 100%;
}


.footer .person-image {
  position: absolute;
  top: -160px;
  left: -40px;
  width: 300px;
  height: auto;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  position: relative;
}

.footer-section {
  flex: 1;
  margin: 20px;
}

.footer-section h2 {
  font-size: 20px;
  margin-top: 20px;
}

.footer-section h3 {
  color: white;
}

.footer-section p {
  margin: 10px 0;
  font-size: 14px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 10px 0;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.apply-button {
  background-color: white;
  color: #007bff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  margin-top: 20px;
  margin-left: -5px;
}

.apply-button:hover {
  background-color: #007bff;
  color: white;
}

.title-column {
  text-align: left;
  /* Alinea el título a la izquierda */
}

.images-column {
  display: flex;
  /* justify-content: space-evenly; /* Distribuye las imágenes uniformemente */
  flex-wrap: wrap;
  /* Permite que las imágenes se envuelvan si es necesario */
  margin-top: 10px;
  margin-right: 120px;
}

.footer-style {
  margin-top: 20px;
  font-size: 14px;
  color: white;
  text-align: center;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-overlay.fade-out {
  animation: fadeOut 0.3s ease-in-out;
}

/* Modal Container */
.modal {
  background-color: #0066ff;
  border-radius: 30px;
  padding: 40px;
  width: 100%;
  height: auto;
  max-width: 500px;
  text-align: center;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1001;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin: 0 auto;
  animation: slideIn 0.3s ease-in-out;
}

.modal.fade-out {
  animation: slideOut 0.3s ease-in-out;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

/* Modal Title */
.modal-title {
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
}

/* Form Inputs */
.modal-input,
.modal-textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border-radius: 15px;
  border: none;
  font-size: 16px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.modal-input-group {
  display: flex;
  gap: 10px;
}

.modal-input-group .modal-input {
  flex: 1;
}

.modal-textarea {
  height: 80px;
  resize: none;
}

/* Character Counter */
.char-counter {
  text-align: right;
  font-size: 14px;
  color: white;
}

/* Submit Button */
.modal-submit-button {
  background-color: #45bc71;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 60%;
  margin-right: 20px;
}

.modal-submit-button:hover {
  background-color: #25D366;
}

/* Close Button */
.modal-close-button {
  background-color: #d84d5b;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease;
  width: 35%;
}

.modal-close-button:hover {
  background-color: #cf3443;
}

.modal-file-label-cv {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0; /* Sin espacio debajo */
  margin-top: 10px; /* Espacio arriba para separarla de los elementos anteriores */
  color: white;
  text-align: left; /* Alinea el texto a la izquierda */
  display: block; /* Asegura que sea un bloque independiente */
}

/* VISTA MÓVIL */
@media (max-width: 768px) {

  /* Footer general */
  .footer {
    padding: 20px 0;
    text-align: center;
    position: relative;
  }

  /* Imagen de la persona ajustada para móviles */
  .footer .person-image {
    position: absolute;
    /* Mantiene la posición absoluta */
    top: -80px;
    /* Ajuste de la posición más alta */
    left: -20px;
    /* Ajuste de la posición a la izquierda */
    width: 150px;
    /* Tamaño más pequeño de la imagen */
    height: auto;
  }

  /* Eliminar la sección SPA y el botón Postula Aquí */
  .title-footer, .description-footer {
    display: none;
    /* Ocultar en vista móvil */
  }

  /* Footer contenido */
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    position: relative;
  }

  /* Sección individual */
  .footer-section {
    flex: 1;
    margin: 10px;
    /* Reducir margen en móviles */
  }

  /* Títulos en móviles */
  .footer-section h3 {
    font-size: 16px;
  }

  .footer-section p {
    font-size: 12px;
  }

  /* Listas de enlaces en móviles */
  .footer-section ul {
    padding: 0;
    list-style: none;
  }

  .footer-section ul li {
    margin: 8px 0;
    /* Reducir margen entre enlaces */
  }

  .footer-section ul li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    /* Texto más pequeño para móviles */
  }

  .footer-section ul li a:hover {
    text-decoration: underline;
  }

  .images-column {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  /* Pie de página final */
  .footer-style {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
  }

  .modal {
    width: 90%;
    padding: 15px;
  }

  .modal-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .modal-input,
  .modal-textarea {
    font-size: 14px;
    padding: 8px;
  }

  .modal-textarea {
    height: 60px;
  }

  .modal-submit-button,
  .modal-close-button {
    font-size: 14px;
    padding: 8px;
  }

  .char-counter {
    font-size: 12px;
  }

  .apply-button {
    font-size: 14px;
    padding: 8px;
    width: 100px;
    z-index: 1000;
    position: relative;
    margin-top: 50px;
    margin-left: -20px;
  }

  .footer-empresa {
    display: none;
  }
}