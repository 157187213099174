/* Contenedor general */
.contactanos-section-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh; /* Asegura que ocupe toda la altura de la pantalla */
  box-sizing: border-box;
  padding: 20px;
}

.container-contact {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.contactanos-title-contact {
  font-size: 48px;
  font-weight: 800;
  color: #1370FB;
  margin-top: 60px;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilo de la tarjeta azul */
.form-card-contact {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1370FB;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  transform: translateX(-100%);
  animation: slideInLeft 1.2s ease-out forwards;
  margin: 20px 0;
  margin-bottom: 100px;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.form-content-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.form-left-contact {
  flex: 2;
  display: flex;
  flex-direction: column;
  color: white;
}

.form-left-contact h2 {
  font-size: 30px;
  font-weight: 800;
  margin-top: -10px;
}

.checkbox-group-contact {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 20px;
}

.checkbox-group-contact label {
  font-size: 14px;
}

.form-input-contact,
.form-textarea-contact {
  width: 410px;
  padding: 15px;
  border: none;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 20px;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.form-textarea-contact {
  height: 80px;
  resize: none;
  border-radius: 22px;
}

.whatsapp-input-contact {
  width: 300px;
  /* Ocupa el espacio restante del contenedor */
  padding: 15px;
  border-radius: 20px 0 0 20px;
  /* Bordes redondeados solo a la izquierda */
  border: none;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  box-sizing: border-box;
  /* Asegura que el padding no afecte el ancho */
}

.submit-button-contact {
  width: auto;
  /* Adapta el ancho al contenido */
  padding: 17.1px 45px;
  background-color: #00C853;
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-left: -30px;
  border: none;
  border-radius: 0 20px 20px 0;
  /* Bordes redondeados solo a la derecha */
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  /* Evita que el texto del botón se quiebre */
}

.submit-button-contact:hover {
  background-color: #00A152;
  /* Cambio de color al pasar el cursor */
}

.sent-message {
  margin-top: 10px;
  font-size: 14px;
  color: #00C853;
}

/* Estilo de la imagen */
.form-right-contact {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-image-contact {
  width: 400px;
  max-width: 800px;
}

/* Responsivo */
@media (max-width: 768px) {
  .contactanos-title-contact {
    font-size: 36px;
    margin-top: 80px;
    text-align: center;
  }

  .form-card-contact {
    width: 100%;
    max-width: 300px;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto 70px auto; /* Centra horizontalmente */
  }

  .form-content-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centra verticalmente */
  }

  .form-left-contact h2 {
    font-size: 24px;
    text-align: center; /* Centra el texto */
  }

  .checkbox-group-contact {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center; /* Centra horizontalmente */
  }

  .checkbox-group-contact label {
    font-size: 12px;
    text-align: center; /* Centra el texto */
  }

  .form-input-contact,
  .form-textarea-contact {
    width: 100%; /* Para asegurarte de que tome el ancho completo disponible */
    max-width: 300px;
    padding: 15px;
    margin: 10px auto; /* Centra horizontalmente */
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 16px;
  }

  .whatsapp-input-contact {
    width: 200px;
    border-radius: 17px 0 0 17px;
    border: none;
    font-size: 16px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    box-sizing: border-box;
  }

  .submit-button-contact {
    padding: 17px 40px;
    background-color: #00C853;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-left: -30px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    position: relative;
    z-index: 1;
  }

  .form-right-contact {
    margin-top: 20px;
    text-align: center; /* Centra el contenido */
  }

  .form-image-contact {
    width: 100%;
    max-width: 250px;
    margin: -20px auto -40px auto; /* Centra horizontalmente */
    display: block;
  }

  .form-left-contact {
    width: 100%;
    text-align: center; /* Centra el texto */
  }
}
