/* NUESTROS PRECIOS HOME */
.seccion-precios {
  text-align: center;
}

.seccion-precios h1 {
  color: #007bff;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 0px;
}

.seccion-precios h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 30px;
}

.price-section-prices {
  padding: 80px;
  background-color: #3598c2;
  text-align: center;
  margin-bottom: 45px;
}

.section-title-prices {
  font-size: 33px;
  margin-bottom: 30px;
  color: #222;
  position: relative;
  display: inline-block;
  margin-top: 10px;
  font-weight: 900;
}

.section-title-prices::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: #1a73e8;
  display: block;
  margin: 8px auto 0;
  border-radius: 2px;
}

.price-list-prices {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 20px;
  position: relative;
  /* Ensure the container is positioned relatively for the absolute image to position correctly */
}

.overlay-image {
  position: absolute;
  top: -30%;
  /* Adjust these values to position the image correctly */
  left: 0;
  width: 400px;
  /* Adjust size as needed */
  height: auto;
  z-index: 1;
  /* Ensure the image is on top */
  opacity: 0;
  transform: translateX(-100px);
  /* Inicialmente fuera de la pantalla a la izquierda */
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.slide-in {
  opacity: 1;
  transform: translateX(0);
  /* La imagen se mueve a su posición original */
}

.price-card-prices {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 220px;
  max-height: auto;
  text-align: center;
  height: 350px;
  opacity: 0;
  transform: translateY(-50px);
  /* Inicialmente fuera de la pantalla arriba */
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.price-card-prices:hover {
  transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
  transform: translateY(-8px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.slide-down {
  opacity: 1;
  transform: translateY(0);
  /* Las tarjetas se mueven a su posición original */
}

/* Opcional: Añadir un retardo para escalonar la animación de las tarjetas */
.price-card-prices:nth-child(2) {
  transition-delay: 0.1s;
}

.price-card-prices:nth-child(3) {
  transition-delay: 0.1s;
}

.price-card-prices:nth-child(4) {
  transition-delay: 0.1s;
}

.image-container-prices {
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
}

.price-image-prices {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.price-card-prices:hover .price-image-prices {
  transform: scale(1.05);
}

.price-name-prices {
  font-size: 1.2rem;
  margin: 10px 0 8px;
  padding: 0 15px;
  color: #333;
}

.price-description-prices {
  font-size: 0.85rem;
  padding: 0 15px 15px;
  color: #777;
  margin-bottom: 20px;
}

.price-price-prices {
  display: block;
  font-size: 1.1rem;
  margin: 10px 15px;
  color: #1a73e8;
  font-weight: bold;
  margin-top: -20px;
}

.buy-button-prices {
  display: block;
  width: calc(100% - 30px);
  margin: 0 15px 15px;
  color: #fff;
  background: linear-gradient(135deg, #1a73e8, #00a0d1);
  border: none;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  /* text-transform: uppercase; */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  position: relative;
  margin-top: 20px;
}

.buy-button-prices:hover {
  background: linear-gradient(135deg, #00a0d1, #007bbf);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

.buy-button-prices:active {
  transform: translateY(-1px);
}

.more-plans {
  display: block;
  width: 15%;
  margin: 0 auto 15px;
  color: #fff;
  background: linear-gradient(135deg, #1a73e8, #00a0d1);
  border: none;
  padding: 15px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  position: relative;
  margin-top: 40px;
}

.more-plans:hover {
  background: linear-gradient(135deg, #00a0d1, #007bbf);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

.more-plans:active {
  transform: translateY(-1px);
}


/* VISTA MOVIL */
@media (max-width: 768px) {  
  .seccion-precios h1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .seccion-precios h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 0px;
    margin-top: -10px;
  }

  .pricing-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .price-card-prices {
    width: 90%;
    max-width: 300px;
    max-height: 320px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .price-card-prices img {
    width: 100%;
    border-radius: 20px;
  }

  .overlay-image {
    display: none;
  }

  .image-container-prices {
    margin-bottom: 0px;
  }

  .price-name-prices {
    font-size: 20px;
    font-weight: 800;
  }

  .price-price-prices {
    font-size: 18px;
    margin-top: 10px;
    color: #1370FB;
    font-weight: bold;
  }

  .buy-button-prices {
    width: 95%;
    height: 50px;
    font-size: 20px;
    margin-bottom: 10px;
    background-color: #1370FB;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }

  .price-description-prices {
    display: none;
  }
}

@media (max-width: 576px) {
  .price-card-prices {
    width: 95%;
    padding: 0px;
  }

  .price-name-prices {
    font-size: 18px;
  }

  .price-price-prices {
    font-size: 13px;
  }

  .buy-button-prices {
    font-size: 14px;
    padding: 10px 18px;
  }

  .more-plans {
    width: 100%; /* Ocupa todo el ancho en pantallas muy pequeñas */
    margin: 0 auto; /* Asegura que esté centrado */
  }
}

@media (max-width: 768px) {
  .pricing-cards-carousel {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 15px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: thin;
  }

  .price-card-prices {
    max-width: 42%;
    height: 40%;
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .buy-button-prices {
    width: 90%;
    height: 40px;
  }

  .price-card-prices img {
    width: 100%;
    border-radius: 0px;
  }

  .more-plans {
    width: 90%; /* Más ancho en pantallas pequeñas */
    margin: 0 auto; /* Centrado horizontalmente */
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
