/* NUESTRAS CATEGORÍAS HOME */
.nuestras-categorias {
  padding: 50px 20px;
  text-align: center;
  margin-bottom: 200px;
}

.nuestras-categorias h1 {
  color: #1370FB;
  font-size: 38px;
}

.nuestras-categorias h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 40px;
}

/* Vista escritorio: mostrar las tarjetas sin carrusel */
.categorias-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap; /* Permite que las tarjetas ocupen el espacio disponible en varias filas si es necesario */
  padding: 30px;
}

.categoria-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 200px;
  height: 200px;
  max-width: 200px;
  max-height: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.categoria-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.categoria-icon img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.categoria-card:hover .categoria-icon img {
  transform: scale(1.1);
}

.categoria-card h3 {
  color: #1370FB;
  font-size: 24px;
  margin-bottom: 10px;
}

.categoria-card p {
  font-size: 16px;
  color: #777;
}

/* Eliminar las flechas del carrusel */
.carrusel-btn {
  display: none; /* Ocultamos las flechas por completo en vista de escritorio */
}

/* VISTA MÓVIL */
@media (max-width: 768px) {
  .nuestras-categorias {
    padding: 20px;
    margin-bottom: 100px;
  }

  .nuestras-categorias h1 {
    font-size: 28px;
  }

  .nuestras-categorias h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  /* En móviles: activar el scroll horizontal */
  .categorias-carrusel {
    display: flex;
    align-items: center;
    overflow-x: auto; /* Habilita el scroll horizontal en móviles */
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .categorias-cards {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap; /* Las tarjetas no saltan a la siguiente línea */
    width: auto;
    padding: 10px 0;
  }

  .categoria-card {
    width: 180px;
    flex: 0 0 auto; /* Asegura que las tarjetas mantengan su tamaño */
    padding: 15px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    scroll-snap-align: start; /* Snap de las tarjetas al hacer scroll */
  }

  .categoria-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .categoria-card p {
    font-size: 14px;
    color: #777;
  }

  .categoria-icon img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

  /* Flechas del carrusel siguen ocultas en móviles */
  .carrusel-btn {
    display: none;
  }
}
