.mas-planes {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* La página debe tener al menos el tamaño completo de la ventana */
}

.contenido-mas-planes {
    flex: 1; /* Empuja el footer al final */
    text-align: center; /* Centrar el contenido */
    margin-top: 50px;
}

.titulo-mas-planes {
    font-size: 33px;
    color: #222;
    position: relative;
    display: inline-block;
    font-weight: 900;
}

/* Linea bajo el título */
.titulo-mas-planes::after {
    content: '';
    width: 100px;
    height: 3px;
    background-color: #1a73e8;
    display: block;
    margin: 8px auto 0;
    border-radius: 2px;
}

.subtitulo-mas-planes {
    font-size: 60px;
    color: #333;
    margin-bottom: 30px;
}

/* Ajuste para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .subtitulo-mas-planes {
        font-size: 40px;
        margin: 100px 0;
    }

    .titulo-mas-planes {
        font-size: 24px;
    }

    .contenido-mas-planes {
        margin-top: 60px;
    }
}
