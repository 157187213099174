body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  text-align: center;
/*   background: linear-gradient(to top, #fff, #fff, #fff, #1370FB ,#1370FB);
 */}

 .whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.whatsapp-button img {
  width: 30px;
  height: 30px;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

