.navbar {
  background: #1370fb;
  top: 0;
  width: 100%;
  height: 25px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.256); */
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 18px;
  position: relative; /* Necesario para ::after */
  transition: color 0.3s ease;
}

.nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px; /* Distancia del subrayado */
  width: 0;
  height: 3px; /* Grosor del subrayado */
  background-color: #ffffff; /* Color del subrayado */
  transition: width 0.3s ease; /* Efecto de expansión */
}

.nav-link:hover::after {
  width: 100%; /* Expande el subrayado */
}

.active-link {
  text-decoration: underline;
}

.logo-empresa {
  width: 180px;
  height: 50px;
  margin-right: 10px;
  margin-top: -12px;
}

/* Menú hamburguesa (solo se muestra en móvil) */
.menu-icon {
  display: none;
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar {
    height: 60px;
    background: #1370fb;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    position: fixed;
    box-sizing: border-box;
  }

  .nav-links {
    max-height: 0; /* Inicialmente oculto */
    opacity: 0;
    overflow: hidden;
    flex-direction: column; /* Asegura que los enlaces se coloquen en columna */
    display: flex;
    align-items: center; /* Centrar los elementos horizontalmente */
    justify-content: center;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100vw;
    background: #2c80ff;
    padding: 0; /* Sin padding inicialmente */
    z-index: 1000;
    border-radius: 40px;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding 0.5s ease;
  }

  /* Mostrar el menú cuando el estado está activo */
  .nav-links.active {
    max-height: 300px; /* Ajusta según la altura del contenido */
    opacity: 1;
    padding: 10px 0; /* Se añade padding cuando se despliega */
  }

  .nav-link {
    display: block; /* Asegura que cada enlace ocupe una nueva línea */
    margin: 10px 0; /* Espaciado entre los enlaces */
    text-align: center;
    width: 100%; /* Hace que los enlaces ocupen todo el ancho disponible */
    opacity: 0;
    transform: translateY(-10px); /* Inicia más arriba */
    transition: transform 0.3s ease, opacity 0.3s ease; /* Transición suave para los enlaces */
    color: white;
    font-size: 18px;
    font-weight: bold;
  }

  /* Mostrar los enlaces con animación hacia abajo cuando el menú esté activo */
  .nav-links.active .nav-link {
    opacity: 1;
    transform: translateY(0); /* Los elementos se desplazan hacia su posición original */
  }

  .menu-icon {
    display: block;
    margin-right: 20px;
    margin-top: -5px;
  }
}


